<template>
    <div class="container">
        <div class="topInfo">
            <p class="title">{{title}}</p>
            <p class="titleEn">{{titleEn}}</p>
        </div>
        <div class="containerBox">
            <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10"
                 infinite-scroll-immediate-check="false">
                <!-- 会员资讯-->
                <div class="contentInfo">
                    <ul class="m-news-info">
                        <li v-for="(item,index) in newsList" @click="toDetail(item)" :key="index">
                            <div class="m-left-img">
                                <img :src="item.fcImage" alt="">
                            </div>
                            <div class="m-right-content">
                                <div class="m-right-title">
                                    <h3>{{item.fcName}}</h3>
                                </div>
                                <p>{{item.year}}-{{item.monthAndDay}}</p>
                            </div>
                        </li>
                    </ul>
                </div>
                <p v-if="noMore" class="null-data">{{nullText}}</p>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: "mobile_memberInfoList",
        data() {
            return {
                newsList: [],
                title: '',
                titleEn: '',
                busy: false,
                noMore: false,
                current: 0,
                nullText: "加载中...",
            }
        },
        watch: {
            $route(to) {
                if (to.path == '/mobile/memberInfo/list') {
                    this.noMore = false
                    this.busy = false
                    this.newsList = []
                    this.current = 1
                    this.getList(1, false)
                }
            }
        },
		created() {
			if (!/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
			    .test(navigator.userAgent)) {
						// console.log(123)
			        this.$router.push({
			            path: "/index"
			        });
			}
		},
        methods: {
            getList(page, flag) {
                this.title = "会员资讯"
                this.titleEn = 'Member Information'
                this.$http.hyzqHyzxList({page: page}).then(res => {
                    if (res.code == 200) {
                        res.data.records.forEach(function (item) {
                            item.monthAndDay = item.monthAndDay.slice(0, 2) + '-' + item.monthAndDay.slice(4, 6)
                        })
                        if (flag) {//如果flag为true则表示分页
                            this.newsList = this.newsList.concat(res.data.records);  //concat数组串联进行合并
                            if (res.data.records.length == 0) {  //如果数据加载完 那么禁用滚动时间 this.busy设置为true
                                this.busy = true;
                                this.noMore = true;
                                this.nullText = '没有更多数据了';
                            } else {
                                this.busy = false;
                                this.noMore = false;
                            }
                        } else {//第一次进入页面 完全不需要数据拼接的
                            this.newsList = res.data.records;
                            this.busy = false;
                            this.nullText = '暂无数据';
                        }
                    }
                })
            },
            loadMore() {
                this.busy = true;
                this.noMore = true;
                this.nullText = '加载中...'
                setTimeout(() => {
                    this.current++; //滚动之后加载第二页
                    if (this.current >= 1) {
                        this.getList(this.current, true);
                    }
                }, 500);
            },
            // 详情跳转
            toDetail(item) {
                this.$router.push({path: '/mobile/memberInfo/detail', query: {id: item.fcId}})
            }
        }
    }
</script>

<style scoped="scoped">
    .container {
        min-height: 100vh;
    }

    .containerBox {
        padding: 2rem 1rem;
    }

    .containerBox > div {
        min-height: 10vh;
    }

    .topInfo {
        padding: 8vw 0 10vw 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border-bottom: solid 1px #e5e5e5;
    }

    .m-news-info {
        overflow: hidden;
        /* margin-top: 8vw; */
    }

    .m-news-info li {
        margin-bottom: 2rem;
        overflow: hidden;
        cursor: pointer;
    }

    .m-news-info li:last-child {
        border-bottom: none;
    }

    .m-left-img {
        float: left;
        margin-right: 1rem;
        width: 7.458rem;
        height: 5.082rem;
        border-radius: .264rem;
        overflow: hidden;
    }

    .m-left-img:hover img {
        transform: scale(1.2);
    }

    .m-left-img img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s ease-in-out;
        -webkit-transition: all .5s ease-in-out;
    }

    .m-right-content {

    }

    .m-right-title {
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .67rem;
    }

    .m-right-title h3 {
        font-weight: normal;
        overflow: hidden;
        width: 100%;
        height: 3.1rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        color: #17191a;
        font-size: 1.122rem;
        line-height: 1.55rem;
    }

    .m-right-content p {
        margin-bottom: 0;
        line-height: 1.3;
        color: #879399;
        font-size: .792rem;
    }

    .title {
        font-size: 5vw;
        color: #000000;
        margin-bottom: 3vw;
    }

    .titleEn {
        font-size: 3vw;
        color: #b3b6b5;
    }

    .newsTitle {
        font-size: 4.5vw;
        color: #000000;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 86vw;
        margin-left: 3vw;
    }

    .m-story-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 2vw;
        margin-top: 4vw;
    }

    .m-story-title {
        padding: 6vw 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
    }

    .dot {
        width: 2vw;
        height: 2vw;
        background-color: #005bac;
        border-radius: 100%;
    }

    .perPublication img {
        width: 43vw;
        height: 58vw;
    }

    .perPublication p {
        font-size: 4vw;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 37vw;

        color: #333333;
        margin-bottom: 2vw;
    }

    .publication {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
        padding-top: 7vw;
        margin-bottom: 4vw;
    }

    .perPublication {
        margin-bottom: 6vw;
        width: 43vw;
        background-color: #f8f8f8;
    }

    .publicationinfo {
        padding: 2vw;
    }

    .corporates {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 10vw 0;
    }

    .perCorporate {
        width: 43vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 8vw;
    }

    .corporateImg {
        width: 43vw;
        height: 20vw;
        background-color: #ffffff;
        border: solid 1px #dbdcdc;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .corporateImg img {
        width: 38vw;
        height: 15vw;
    }

    .perCorporate p {
        width: 38vw;
        text-align: center;
        margin-top: 3vw;
        font-size: 4vw;
        color: #333333;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>
